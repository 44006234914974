@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans");

body {
  margin: 0;
  font-family: "Alegreya";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  margin: auto;
  padding: 1em;
  max-width: 70ch;
  line-height: 1.3;
  font-size: 18px;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;
}

a,
button.link {
  color: #7253ed;
  text-decoration: none;

  border-bottom: 1px solid #eeebee;
  cursor: pointer;
}

button.link:hover,
a:hover {
  border-bottom: 1px solid #7253ed;
}

h1 {
  font-size: 1.2em;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

h2 {
  font-size: 1.1em;
  margin-top: 0.25rem;
  margin-bottom: 0.125rem;
}

p {
  margin: 0;
}

ul {
  margin: 0.5em 0;
}

.attribution {
  margin-top: 2em;
}

article {
  max-width: 50ch;
}
